import React from 'react';
import styles from './Service.module.css'; // Import CSS module

function Service({ title, description, features,html }) {
  return (
    <div className={styles.service}>
      <h2>{title}</h2>
      <p>{description}</p>
      <br />
      <h3>Features</h3>
      <ul>
        {features.map((feature, index) => (
          <li key={index}>{feature}</li>
        ))}
      </ul>
      <br />
      {html}
    </div>
  );
}

function ServicePage() {
  const services = [
    {
      title: 'Tally Customization',
      description: 'Every organization during its journey creates its own niche which puts them into the growth path. This leads to unique business requirements which require to be incorporated in to the standard Tally software. Business Growth demands change in business processes, securities & controls, Management Information Systems (MIS), automization at various levels, integration of different functions & software. The default Tally Prime/ TallyPrime is packed with powerful functionalities & feature which would address 90% of the businesses. The balance unique requirements can be incorporated by customizing Tally. In a way Tally provides best of both the world i.e. Packages software & customized software. Customize Tally to fit in exactly the way your business or organization wishes to operate.',
      features: ['Automated invoice generation', 'Custom reports and documents', 'Integration with existing systems'],
      html: <p><b>How is Tally Customization done? </b><br/>

      Tally Prime is architected to be a platform and is designed to support extensive Tally Customisation. Tally Prime comes with an execution Kernel, an interpreter and a development language Tally Definition Language (TDL). Using TDL, you can enhance or change the default behavior of Tally Prime. Extend core functionality by interfacing a bespoke application facilitated by Tally Prime’s support for custom built DLLs and XSLT.
      
      Tally Prime supports ODBC and import & export of data in standard data formats like XML & CSV. Tally Prime has a SOAP listener, which helps in online integration and with other business tools.<br/>
      <br /><br /><b>Tally Customization Process </b><br/>RC Services interacts closely with the customers, understand their business functions, specific needs, processes & documents it. The team then checks how much of the requirement can be satisfied by using the default functionality of the Tally software. If there is a Gap, it is identified and taken up for Tally Customisation. In a way RC Services make sure that the customer takes maximum advantage of Tally strengths. <br /></p>
    },
    {
      title: 'Biz Analyst',
      html:"",
      description: 'Access Tally on your mobile device securely and in real-time, making business decisions faster and data-driven.',
      features: ['Real-time business insights', 'Secure mobile access to data', 'Inventory management on the go']
    },
    {
      title: 'TSplus',
      html:"",
      description: 'The ideal alternative to Citrix and Microsoft RDS for remote desktop access and Windows application delivery. Web-enable your legacy apps, create SaaS solutions or remotely access your centralized corporate tools and files. Enable remote access to your desktop and applications, making your business accessible from anywhere in the world.',
      features: ['Remote desktop and application access', 'Secure and reliable connections', 'Easy to set up and manage']
    }
  ];

  return (
    <div className="container">
        <style>{`.container {
    width: 80%;
    margin: 0 auto;
}

.text-center {
    text-align: center;
}

.my-5 {
    margin-top: 30px;
    margin-bottom: 30px;
}

.row {
    display: flex;
    justify-content: space-between;
}

@media (max-width: 768px) {
    .row {
        flex-direction: column;
    }
}
`}</style>
      <header className="text-center my-5">
        <h1>Our Services</h1>
        <p>Enhance your business with our integrated solutions</p>
      </header>
      <div className="row">
        {services.map((service, index) => (
          <Service key={index} {...service} />
        ))}
      </div>
    </div>
  );
}

export default ServicePage;


